@import 'variables';

.game {
  user-select: none;

  .game__info {
    user-select: none;
    color: $color;
  }

  &__container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__content {
    position: relative;
    background: $color2;
    overflow: auto;
    border: 1px solid;
  }
}