.score {
  font-size: 2rem;
  line-height: 1;
  color: #fff;
  text-align: center;
  font-weight: 600;
  padding: 0.5rem 1rem;
  position: relative;
  background: #bbada0;
  border-radius: 0.25rem;

  &:before {
    font-size: 0.875rem;
    line-height: 1;
    color: #eee4da;
    text-transform: uppercase;
    display: block;
    padding-bottom: 0.5rem;
  }

  &-addition {
    font-size: 1.75rem;
    line-height: 1;
    color: rgba(#776e65, 0.9);
    font-weight: 600;
    z-index: 2;
    position: absolute;
    right: 30px;
    animation: move-up 0.6s ease-in;
    animation-fill-mode: both;
  }

  &.current-score {
    border-right: 1px solid #fff;
    border-radius: 0.25rem 0 0 0.25rem;

    &::before {
      content: 'Score';
    }
  }

  &.best-score {
    border-radius: 0 0.25rem 0.25rem 0;

    &::before {
      content: 'Best';
    }
  }
}
