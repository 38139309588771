@import './src/scss/variables';
@import './src/scss/game';

$grid_cell_background_color: rgba(217, 213, 210, 0.35);
$grid_cell_border: #ccc;
$grid_cell_size: calc(131px - (var(--grid-size) * 8px)); // change size grid cell

:root {
  --grid-size: 4;
}

$max_grid_size: 8;

@import 'tools';
@import 'keyframes';
@import 'score';
@import 'grid';
@import 'tile';
@import 'dark-theme';

.game__main {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  cursor: default;
  user-select: none;
}

.game__container {
  position: relative;
}

.current-score {
  position: relative;
}