.grid {
  position: relative;

  &__row {
    display: flex;
    margin: 0;

    &:first-child {
      .grid__cell {
        border-top: none;
      }
    }

    &:last-child {
      .grid__cell {
        border-bottom: none;
      }
    }

    .grid__cell {
      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  &__cell {
    width: $grid_cell_size;
    height: 0;
    padding-bottom: $grid_cell_size;
    border-right: 1px solid;
    border-bottom: 1px solid;
    box-sizing: content-box !important;

    &:last-child {
      margin-right: 0;
    }
  }
}