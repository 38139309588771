.tile {
  text-align: center;
  width: $grid_cell_size;
  height: $grid_cell_size;
  position: absolute;
  z-index: 1;
  color: $color2;
  font-weight: 600;
  background-color: $color;
  border-radius: 50%;
  border: .5rem solid $color2;
  transition: .2s ease-in-out;
  transition-property: top, left;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size5;
  //border: 4px solid $color2;
  //border-radius: 50%;
  cursor: pointer;

  &s {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &_correct {
    //color: $color2;
    text-decoration: underline;
  }

  @for $x from 1 through $max_grid_size {
    // TODO use custom
    @for $y from 1 through $max_grid_size {
      // TODO use custom
      &.tile-position-#{$x}-#{$y} {
        left: calc((#{$grid_cell_size} + 1px) * #{$x - 1});
        top: calc((#{$grid_cell_size} + 1px) * #{$y - 1});
      }
    }
  }

  &-new {
    animation: appear .2s ease .4s;
    animation-fill-mode: both;
  }

  &-merged {
    z-index: 2;
    animation: pop .2s ease .4s;
    animation-fill-mode: both;
  }
}